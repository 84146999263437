import * as Sentry from "@sentry/vue";
import router from "../router";

const tracesSR = parseFloat(
    import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0.5
);
const environment = import.meta.env.VITE_SENTRY_ENV ?? import.meta.env.MODE;
const replaysSessionSR = parseFloat(
    import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? 0.1
);
const replaysErrorSR = parseFloat(
    import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? 1.0
);

function init(app) {
    Sentry.init({
        app,
        dsn: "https://8b5b2223cd0ffd3c29bf53e81a32f498@sentry.suonovivo.com/12",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay({
                maskAllText: false,
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: tracesSR,
        environment: environment,
        replaysSessionSampleRate: replaysSessionSR,
        replaysOnErrorSampleRate: replaysErrorSR,
        attachStacktrace: true,
    });
}

function setEvent(slug) {
    Sentry.setTag("event", slug);
}

function setSeat(seat) {
    Sentry.setTag("seat", seat);
}

function setColor(color) {
    Sentry.setTag("color", color);
}

export default { init, setEvent, setSeat, setColor };
