import axios from "axios";

const instance = axios.create({
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    baseURL: import.meta.env.VITE_API_URL,
});

export default instance;