import { defineStore } from "pinia";
import events from "../services/events";
import ws from "@/ws";
import { useCurrentStore } from "@/stores/current";
import sentry from "@/services/sentry";

const statusRoutes = {
    choosing: "choosing",
    color: "color",
    stars: "stars",
    show: "show",
    conclusion: "conclusion",
};

export const useEventStore = defineStore("event", {
    state: () => ({ event: {} }),
    actions: {
        async fetchEvent(slug) {
            const r = await events.getEventInfo(slug);
            this.event = r.data;
        },
        setStatus(status) {
            this.event.status = status;
        },
        setSlug(slug) {
            if (this.slug === slug) return;
            this.slug = slug;
            sentry.setEvent(slug);
            ws.setEvent(slug);
        },
        setColors(colors) {
            if (!this.event) return;
            this.event.colors = colors;
        },
        setColorOpen(color, open) {
            if (!this.event) return;
            if (!this.event.colors) return;
            this.event.colors.find((c) => c.code === color).open = open;
        },
    },
    getters: {
        getEvent: (state) => state.event,
        getSlug: (state) => state.getEvent?.slug,
        getStatus: (state) => state.getEvent?.status || "choosing",
        getColor: (state) => {
            const code = useCurrentStore().getCode;
            if (!code) return null;
            const event = state.getEvent;
            if (!event?.colors) return null;
            return event.colors.find((c) => c.code === code);
        },
        getStatusRoute: (state) => {
            const defaultRoute = "choosing";
            const status = state.getStatus || defaultRoute;
            return statusRoutes[status] || defaultRoute;
        },
        getCheckColorBckInterval: (state) =>
            state.getEvent?.intervalCheckColorsBckMs || 20000,
    },
});
