import { defineStore } from "pinia";
import ws from "../ws";
import { useCurrentStore } from "./current";

export const usePollStore = defineStore("poll", {
    state: () => ({ poll: null, answerId: null }),
    actions: {
        async sendAnswer() {
            const id = this.poll.sessionId || this.poll.id;
            await ws.answerPoll(this.poll.id, this.answerId);
            this.answerId = null;
            localStorage.setItem("lastPollSessionVoted", id.toString());
        },
        setPoll(poll) {
            if (useCurrentStore().admin) return;
            if (!poll) {
                this.poll = poll;
                return;
            }

            const id = poll.sessionId || poll.id;
            const lastPollSessionVoted = localStorage.getItem(
                "lastPollSessionVoted"
            );
            if (
                lastPollSessionVoted &&
                parseInt(lastPollSessionVoted) === parseInt(id)
            ) {
                console.log(`${id} - already voted`);
                return;
            }
            this.poll = poll;
        },
        setAnswer(id) {
            this.answerId = id;
        },
    },
});
