import socket from "./socketIoInstance";

function setSeat(seat) {
    return socket.emit("seat", seat);
}

function setEvent(slug) {
    return socket.emit("event", slug);
}

function answerPoll(pollId, answerId) {
    return socket.emit("poll", { pollId, answerId });
}

function setLanguage(language) {
    return socket.emit("language", language);
}

function sendFeedback(id, rating) {
    return socket.emit("feedback", id, rating);
}

function setColor(color) {
    return socket.emit("color", color);
}

export default {
    setSeat,
    setEvent,
    setLanguage,
    answerPoll,
    sendFeedback,
    setColor,
};
