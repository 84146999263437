<template>
    <router-view v-slot="{ Component }">
        <transition>
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useEventStore } from "@/stores/event";
import dynamicRoutes from "@utils/dynamicRoutes";
import { useCurrentStore } from "@/stores/current";

const route = useRoute();
const router = useRouter();

watch(
    () => useEventStore().getStatusRoute,
    (newVal) => {
        if (typeof newVal !== "string") return;
        const routeName = route.name ?? "";
        // console.log("Actual route:", routeName);
        // console.log("New route:", newVal);
        if (dynamicRoutes.includes(routeName)) {
            if (newVal === routeName) return;
            router.push({ name: newVal });
        }
    }
);

function enableNoSleep() {
    useCurrentStore().enableNoSleep();
}

onMounted(() => {
    document.body.addEventListener("click", enableNoSleep);
    // this is for backup; the main method to get the status of the color is through the websocket
    fetchEventTimeout.value = setTimeout(
        fetchEvent,
        useEventStore().getCheckColorBckInterval
    );
});

onUnmounted(() => {
    document.body.removeEventListener("click", enableNoSleep);
    clearInterval(fetchEventTimeout.value);
});

const fetchEventTimeout = ref(null);

function fetchEvent() {
    const slug = useEventStore().getSlug;
    if (!slug) {
        fetchEventTimeout.value = setTimeout(
            fetchEvent,
            useEventStore().getCheckColorBckInterval
        );
        return;
    }
    useEventStore()
        .fetchEvent(slug)
        .then(() => {
            fetchEventTimeout.value = setTimeout(
                fetchEvent,
                useEventStore().getCheckColorBckInterval
            );
        })
        .catch(() => {
            console.error("Error while fetching event");
            fetchEventTimeout.value = setTimeout(
                fetchEvent,
                useEventStore().getCheckColorBckInterval
            );
        });
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
