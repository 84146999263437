import axios from "./axiosInstance";

function get() {
    return axios.get("/admin/events");
}

function create(slug) {
    return axios.post("/admin/events", { slug });
}

function edit(slug, data) {
    return axios.patch(`/admin/events/${slug}`, data);
}

function getLanguages(slug) {
    return axios.get(`/admin/events/${slug}/languages`);
}

function setLanguages(slug, languages) {
    return axios.put(`/admin/events/${slug}/languages`, languages);
}

function getChannels(slug) {
    return axios.get(`/admin/events/${slug}/channels`);
}

function addChannel(slug, data) {
    return axios.post(`/admin/events/${slug}/channels`, data);
}

function addLanguage(slug, language) {
    return axios.post(`/admin/events/${slug}/languages`, { language });
}

function getEventInfo(slug) {
    return axios.get(`/events/${slug}`);
}

function getPolls(slug) {
    return axios.get(`/events/${slug}/polls`);
}

function sendPoll(slug, pollId) {
    return axios.post(`/events/${slug}/sendPoll`, { pollId });
}

function retractPoll(slug, pollId) {
    return axios.post(`/events/${slug}/retractPoll`, { pollId });
}

function resetPoll(slug, pollId) {
    return axios.post(`/events/${slug}/resetPoll`, { pollId });
}

function showPollResults(slug, pollId) {
    return axios.post(`/events/${slug}/showPollResults`, { pollId });
}

function retractPollResults(slug, pollId) {
    return axios.post(`/events/${slug}/retractPollResults`, { pollId });
}

function setStatus(slug, status) {
    return axios.patch(`/admin/events/${slug}/status`, { status });
}

export default {
    get,
    create,
    edit,
    getLanguages,
    setLanguages,
    addLanguage,
    getChannels,
    addChannel,
    getEventInfo,
    getPolls,
    sendPoll,
    retractPoll,
    resetPoll,
    showPollResults,
    retractPollResults,
    setStatus,
};
