import { io } from "socket.io-client";
import { useLightsStore } from "@/stores/lights";
import { useEventStore } from "@/stores/event";
import { usePollStore } from "@/stores/poll";
import { useFeedbackStore } from "@/stores/feedback";
import { useCurrentStore } from "@/stores/current";

const socket = io(import.meta.env.VITE_API_URL, { withCredentials: true });

socket.on("connect", () => {
    console.log("socket connected", socket.id);
    socket.emit("event", useEventStore().getSlug);
});

socket.on("disconnect", (reason, description) => {
    console.log("socket disconnected", reason, description);
});

socket.on("c", (c) => {
    useLightsStore().setColor(c);
});

socket.on("lights", async (value) => {
    await useLightsStore().setShow(value === "on");
});

socket.on("randomColor", (value) => {
    useLightsStore().setRandomColor(value);
});

socket.on("poll", async (value) => {
    await usePollStore().setPoll(value);
});

socket.on("feedback", (value) => {
    useFeedbackStore().setFeedback(value);
});

socket.on("feedback", (titles, messages) => {
    // titles = {"IT": "Titolo", "EN": "Title"}
    // messages = {"IT": "Messaggio", "EN": "Message"}
    useFeedbackStore().open(titles, messages);
});

socket.on("eventStatus", (status) => {
    useEventStore().setStatus(status);
});

socket.on("colors", (colors) => {
    useEventStore().setColors(colors);
});

socket.on("setColorOpen", (color, open) => {
    // if open is a string, convert it to boolean
    if (typeof open === "string") open = open === "true" || open === "1";
    useEventStore().setColorOpen(color, open);
});

socket.on("refresh-color", (code) => {
    const color = useCurrentStore().getCode;
    if (color !== code) return;
    window.location.reload();
});

export default socket;
