import { defineStore } from "pinia";

export const useLightsStore = defineStore("lights", {
    state: () => ({
        show: false,
        color: "#FFFFFF",
        randomColor: { enabled: false, colors: [] },
        randomColorSelected: "",
    }),
    actions: {
        setColor(value) {
            this.color = "#" + value.replace("#", "");
        },
        setShow(value) {
            this.show = value;
        },
        setRandomColor(value) {
            this.randomColor = value;
            if (value.enabled) {
                this.randomColorSelected =
                    value.colors[
                        Math.floor(Math.random() * value.colors.length)
                    ];
            }
        },
    },
    getters: {
        getColor: (state) => {
            if (state.randomColor.enabled)
                return "#" + state.randomColorSelected.replace("#", "");
            return state.color;
        },
    },
});
