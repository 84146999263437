import { ref } from "vue";
import { defineStore } from "pinia";
import Janus from "@/services/janus";

export const useWebRTCStore = defineStore("webrtc", () => {
    const janus = ref(null);
    const streaming = ref(null);
    const streams = ref([]);

    function initialize(url, callback) {
        if (janus.value) {
            callback();
            return;
        }
        Janus.init({
            debug: import.meta.env.VITE_JANUS_DEBUG,
            callback: function () {
                Janus.log(" ::: Got Janus :::");
            },
        });
        janus.value = new Janus({
            server: url,
            success: callback,
            error: function (error) {
                console.error("Janus connection error", error);
            },
        });
    }

    function attachToStreamingPlugin() {
        return new Promise((resolve, reject) => {
            if (!janus.value) {
                reject("Janus not initialized");
                return;
            }
            if (streaming.value) {
                resolve();
                return;
            }
            janus.value.attach({
                plugin: "janus.plugin.streaming",
                opaqueId: "streamingtest-" + Janus.randomString(12),
                success: function (pluginHandle) {
                    streaming.value = pluginHandle;
                    Janus.log(
                        "Plugin attached! (" +
                            streaming.value.getPlugin() +
                            ", id=" +
                            streaming.value.getId() +
                            ")"
                    );
                    resolve();
                },
                error: function (error) {
                    reject(error);
                },
                onmessage: function (msg, jsep) {
                    Janus.log(" ::: Got a message :::");
                    Janus.log(msg);
                    if (jsep) {
                        Janus.debug("Handling SDP as well...", jsep);
                        let stereo = jsep.sdp.indexOf("stereo=1") !== -1;
                        // Offer from the plugin, let's answer
                        streaming.value.createAnswer({
                            jsep: jsep,
                            // We only specify data channels here, as this way in
                            // case they were offered we'll enable them. Since we
                            // don't mention audio or video tracks, we autoaccept them
                            // as recvonly (since we won't capture anything ourselves)
                            tracks: [{ type: "data" }],
                            customizeSdp: function (jsep) {
                                if (
                                    stereo &&
                                    jsep.sdp.indexOf("stereo=1") === -1
                                ) {
                                    // Make sure that our offer contains stereo too
                                    jsep.sdp = jsep.sdp.replace(
                                        "useinbandfec=1",
                                        "useinbandfec=1;stereo=1"
                                    );
                                }
                            },
                            success: function (jsep) {
                                Janus.debug("Got SDP!", jsep);
                                let body = { request: "start" };
                                streaming.value.send({
                                    message: body,
                                    jsep: jsep,
                                });
                            },
                            error: function (error) {
                                Janus.error("WebRTC error:", error);
                            },
                        });
                    }
                },
                onremotetrack: function (track, mid, on, metadata) {
                    Janus.debug(
                        "Remote track (mid=" +
                            mid +
                            ") " +
                            (on ? "added" : "removed") +
                            (metadata ? " (" + metadata.reason + ") " : "") +
                            ":",
                        track
                    );
                    Janus.attachMediaStream(
                        document.getElementById("remoteAudio"),
                        new MediaStream([track])
                    );
                },
            });
        });
    }

    function listStreams() {
        streaming.value.send({
            message: { request: "list" },
            success: function (result) {
                if (result["list"]) {
                    Janus.log("Got a list of available streams");
                    Janus.log(result["list"]);
                    streams.value = result["list"];
                }
            },
        });
    }

    function playStream(id) {
        Janus.log("Selected stream id #" + id);
        streaming.value.send({
            message: {
                request: "watch",
                id: id,
            },
        });
    }

    function stopStream() {
        streaming.value.send({
            message: {
                request: "stop",
            },
        });
    }

    window.streaming = streaming.value;

    return {
        initialize,
        attachToStreamingPlugin,
        playStream,
        stopStream,
        listStreams,
        getStreamIdByRoomId: (roomId) => {
            return streams.value.find(
                (stream) => stream.description === "room-" + roomId
            )?.id;
        },
    };
});
