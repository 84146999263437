import { defineStore } from "pinia";
import ws from "@/ws";

export const useFeedbackStore = defineStore("feedback", {
    state: () => ({ rating: 0, feedback: null }),
    actions: {
        setFeedback(feedback) {
            this.feedback = feedback;
        },
        setRating(n) {
            this.rating = n;
        },
        submit() {
            ws.sendFeedback(this.feedback.id, this.rating);
            this.rating = 0; // reset rating
            this.feedback = null;
        },
    },
    getters: {
        ratingSufficient: (state) => state.rating >= 3,
        active: (state) => state.feedback !== null,
    },
});
