import { createRouter, createWebHistory } from "vue-router";
import { useCurrentStore } from "@/stores/current";
import { useEventStore } from "@/stores/event";
import dynamicRoutes from "@utils/dynamicRoutes";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
    },
    {
        path: "/intro",
        name: "intro",
        component: () => import("@/views/IntroView.vue"),
        meta: { requiresLogos: true },
    },
    {
        path: "/choosing",
        name: "choosing",
        component: () => import("@/views/ChoosingView.vue"),
        meta: { requiresLogos: true, requiresIntro: true },
    },
    {
        path: "/color",
        name: "color",
        component: () => import("@/views/ColorView.vue"),
        meta: { requiresLogos: true },
    },
    {
        path: "/stars",
        name: "stars",
        component: () => import("@/views/StarsView.vue"),
        meta: { requiresLogos: true },
    },
    {
        path: "/show",
        name: "show",
        component: () => import("@/views/ShowView.vue"),
        meta: { requiresLogos: true },
    },
    {
        path: "/conclusion",
        name: "conclusion",
        component: () => import("@/views/ConclusionView.vue"),
        meta: { requiresLogos: true },
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // keep the code in the query string (so that it can be shared)
    if (useCurrentStore().getCode && !to.query?.c) {
        to.query.c = useCurrentStore().getCode;
        return next({ path: to.path, query: to.query });
    }

    if (to.query.c) {
        useCurrentStore().setCode(to.query.c);
    }

    const statusRoute = useEventStore().getStatusRoute;
    if (statusRoute !== "choosing") {
        // if the event is not in the choosing phase, bypass the intro
        useCurrentStore().setBypassIntro(true);
    }

    if (to.meta.requiresLogos && !useCurrentStore().getLogosShown) {
        return next({ name: "home" });
    }

    if (
        to.meta.requiresIntro &&
        !useCurrentStore().getBypassIntro &&
        !useCurrentStore().getIntroShown
    ) {
        return next({ name: "intro" });
    }

    const routeName = to.name || "";
    if (dynamicRoutes.includes(routeName)) {
        if (routeName !== statusRoute) {
            // console.log("redirecting to", statusRoute);
            return next({ name: statusRoute });
        }
    }

    next();
});

router.afterEach(() => {
    const envEventSlug = import.meta.env.VITE_EVENT_SLUG;
    useEventStore().setSlug(envEventSlug);
});

export default router;
